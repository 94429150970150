var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-community')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{ref:"podcast",attrs:{"color":"#556ee6","enctype":"multipart/form-data","finishButtonText":this.$route.name == 'add-community' ? 'Save Data' : 'Update Data',"back-button-text":"Go Back!","next-button-text":"Go Next!"},on:{"on-complete":_vm.submitData}},[_c('tab-content',{attrs:{"title":"Community Details","icon":"mdi mdi-account-details","before-change":() => _vm.validateFormOne()}},[_c('b-form-group',[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Community Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.title.$error,
                },attrs:{"id":"input-1","placeholder":"Enter Community Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.submitted && !_vm.$v.form.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Community Title is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"position-relative",attrs:{"label":"Tell Us More About Community","label-for":"input-4"}},[_c('ckeditor',{attrs:{"maxlength":400},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"text-right"},[(_vm.form.description)?_c('p',{staticClass:"badge position-absolute",class:{
                    'badge-success': _vm.form.description.length !== 400,
                    'badge-danger': _vm.form.description.length === 400
                  },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.description.length)+" out of 400 chars. ")]):_vm._e()])],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-2","label":"Tagline","label-for":"input-2"}},[_c('b-form-input',{attrs:{"id":"input-2","maxlength":250,"placeholder":"Enter Tagline"},model:{value:(_vm.form.tag_line),callback:function ($$v) {_vm.$set(_vm.form, "tag_line", $$v)},expression:"form.tag_line"}}),_c('div',{staticClass:"text-right"},[(_vm.form.tag_line)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.tag_line.length !== 250,
                      'badge-danger': _vm.form.tag_line.length === 250
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.tag_line.length)+" out of 250 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-8"}},[_c('label',{attrs:{"for":"input-8"}},[_vm._v("News Card Color "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.news_card_color.$invalid,
                    },attrs:{"id":"input-8","placeholder":"Enter News Card Color [Eg: #00bcd4]"},model:{value:(_vm.form.news_card_color),callback:function ($$v) {_vm.$set(_vm.form, "news_card_color", $$v)},expression:"form.news_card_color"}}),(_vm.submitted && !_vm.$v.form.news_card_color.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" News Card Color is required. ")]):_vm._e()],1)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-14","label":"Thumbnail Image [Upload Max File Size : 2MB]","label-for":"input-14"}},[_c('b-form-file',{ref:"thumbnail_image",attrs:{"id":"input-14","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'thumbnail_image')}}}),(
                    _vm.$route.name == 'edit-community' &&
                    _vm.edit.thumbnail_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.thumbnail_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.thumbnail_image_url)?[_c('img',{ref:"thumbnail_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.thumbnail_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-9","label":"Pre Login Image [Upload Max File Size : 2MB]","label-for":"input-9"}},[_c('b-form-file',{ref:"pre_login_image",attrs:{"id":"input-9","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'pre_login_image')}}}),(
                    _vm.$route.name == 'edit-community' &&
                    _vm.edit.pre_login_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.pre_login_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.pre_login_image_url)?[_c('img',{ref:"pre_login_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.pre_login_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-10","label":"Mobile Screen Hand Banner [Upload Max File Size : 2MB]","label-for":"input-10"}},[_c('b-form-file',{ref:"pre_login_image2",attrs:{"id":"input-10","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'pre_login_image2')}}}),(
                    _vm.$route.name == 'edit-community' &&
                    _vm.edit.pre_login_image2_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.pre_login_image2_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.pre_login_image2_url)?[_c('img',{ref:"pre_login_image2_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.pre_login_image2_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-13","label":"Website Banner Image [Upload Max File Size : 2MB]","label-for":"input-13"}},[_c('b-form-file',{ref:"website_banner_image",attrs:{"id":"input-13","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'website_banner_image')}}}),(
                    _vm.$route.name == 'edit-community' &&
                    _vm.edit.website_banner_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.website_banner_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.website_banner_image_url)?[_c('img',{ref:"website_banner_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.website_banner_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-12"}},[_c('label',{attrs:{"for":"input-12"}},[_vm._v("Banner Image [Upload Max File Size : 2MB] "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-file',{ref:"banner_image",class:{
                      'is-invalid': _vm.submitted && _vm.$v.banner_image.$invalid,
                    },attrs:{"id":"input-12","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'banner_image')}}}),(
                    _vm.$route.name == 'edit-community' && _vm.edit.banner_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.banner_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.banner_image_url)?[_c('img',{ref:"banner_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.banner_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.submitted && !_vm.$v.banner_image.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Banner Image is required. ")]):_vm._e()],2),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-11"}},[_c('label',{attrs:{"for":"input-11"}},[_vm._v("Logo Image [Upload Max File Size : 2MB] "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-file',{ref:"logo_image",class:{
                      'is-invalid': _vm.submitted && _vm.$v.logo_image.$invalid,
                    },attrs:{"id":"input-11","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'logo_image')}}}),(
                    _vm.$route.name == 'edit-community' && _vm.edit.logo_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.logo_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.logo_image_url)?[_c('img',{ref:"logo_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.logo_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.submitted && !_vm.$v.logo_image.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Logo Image is required. ")]):_vm._e()],2)],1)],1),_c('tab-content',{attrs:{"title":"Add Tags","icon":"fa fa-tag"}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-5","label":"Meta Title","label-for":"input-5"}},[_c('b-form-input',{attrs:{"id":"input-5","maxlength":190,"placeholder":"Enter Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_title)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_title.length !== 190,
                      'badge-danger': _vm.form.meta_title.length === 190
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_title.length)+" out of 190 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"label":"Meta Keywords","label-for":"input-7"}},[_c('b-form-input',{attrs:{"id":"input-7","maxlength":250,"placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_keywords)?_c('p',{staticClass:"badge position-absolute",class:{
                        'badge-success': _vm.form.meta_keywords.length !== 250,
                        'badge-danger': _vm.form.meta_keywords.length === 250
                      },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_keywords.length)+" out of 250 chars. ")]):_vm._e()])],1)],1),_c('b-form-group',{staticClass:"mb-4 position-relative",attrs:{"id":"input-group-6","label":"Meta Description","label-for":"input-6"}},[_c('b-form-textarea',{attrs:{"id":"input-6","placeholder":"Enter Meta Description","rows":"3","maxlength":400},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_description)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_description.length !== 400,
                      'badge-danger': _vm.form.meta_description.length === 400
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_description.length)+" out of 400 chars. ")]):_vm._e()])],1),(_vm.$route.name == 'edit-community')?[_c('h6',[_vm._v("Tabs Listed:")]),_vm._l((_vm.forum_tabs),function(data,i){return _c('div',{key:i},[_c('label',[_vm._v("Label for "+_vm._s(i)+" :")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label-for":"case"}},[_c('b-form-input',{attrs:{"id":"case","type":"number","placeholder":"Index"},model:{value:(data.index),callback:function ($$v) {_vm.$set(data, "index", $$v)},expression:"data.index"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Enter Label"},model:{value:(data.label),callback:function ($$v) {_vm.$set(data, "label", $$v)},expression:"data.label"}})],1)],1),_c('div',{staticClass:"col-md-4",staticStyle:{"display":"flex","align-items":"start","padding-top":"10px"}},[_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('b-form-checkbox',{model:{value:(data.active),callback:function ($$v) {_vm.$set(data, "active", $$v)},expression:"data.active"}},[_vm._v("Active")])],1)])])])})]:_vm._e(),_c('b-form-group',{attrs:{"id":"input-group-15"}},[_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('b-form-checkbox',{model:{value:(_vm.form.available_for_registration),callback:function ($$v) {_vm.$set(_vm.form, "available_for_registration", $$v)},expression:"form.available_for_registration"}},[_vm._v("Available for Live Event Registration ")]),_c('b-form-checkbox',{model:{value:(_vm.form.show_in_home_page),callback:function ($$v) {_vm.$set(_vm.form, "show_in_home_page", $$v)},expression:"form.show_in_home_page"}},[_vm._v("Show in Home Page")])],1)])],2)],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }