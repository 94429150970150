<template>
  <Layout>
    <template v-if="this.$route.name == 'add-community'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard @on-complete="submitData" color="#556ee6" enctype="multipart/form-data" ref="podcast"
              :finishButtonText="this.$route.name == 'add-community' ? 'Save Data' : 'Update Data'"
              back-button-text="Go Back!" next-button-text="Go Next!">
              <tab-content title="Community Details" icon="mdi mdi-account-details"
                :before-change="() => validateFormOne()">
                <b-form-group>
                  <label for="input-1">Community Title <span style="color: red">*</span></label>
                  <b-form-input id="input-1" v-model="form.title" placeholder="Enter Community Title" :class="{
                    'is-invalid': submitted && $v.form.title.$error,
                  }"></b-form-input>
                  <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                    Community Title is required.
                  </div>
                </b-form-group>
                <b-form-group label="Tell Us More About Community" class="position-relative" label-for="input-4">
                  <ckeditor v-model="form.description" :maxlength="400"></ckeditor>
                  <div class="text-right">
                    <p v-if="form.description" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                      'badge-success': form.description.length !== 400,
                      'badge-danger': form.description.length === 400
                    }">
                      You typed
                      {{ form.description.length }} out of 400 chars.
                    </p>
                  </div>
                </b-form-group>
                <div class="row">
                  <b-form-group class="col-6 position-relative" id="input-group-2" label="Tagline" label-for="input-2">
                    <b-form-input id="input-2" :maxlength="250" v-model="form.tag_line" placeholder="Enter Tagline">
                    </b-form-input>
                    <div class="text-right">
                      <p v-if="form.tag_line" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.tag_line.length !== 250,
                        'badge-danger': form.tag_line.length === 250
                      }">
                        You typed
                        {{ form.tag_line.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>

                  <b-form-group id="input-group-8" class="col-6">
                    <label for="input-8">News Card Color <span style="color: red">*</span></label>
                    <b-form-input id="input-8" v-model="form.news_card_color"
                      placeholder="Enter News Card Color [Eg: #00bcd4]" :class="{
                        'is-invalid': submitted && $v.form.news_card_color.$invalid,
                      }"></b-form-input>
                    <div v-if="submitted && !$v.form.news_card_color.required" class="invalid-feedback">
                      News Card Color is required.
                    </div>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group id="input-group-14" label="Thumbnail Image [Upload Max File Size : 2MB]"
                    label-for="input-14" class="col-6">
                    <b-form-file id="input-14" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'thumbnail_image')" ref="thumbnail_image"></b-form-file>
                    <template v-if="
                      $route.name == 'edit-community' &&
                      edit.thumbnail_image_url
                    ">
                      <img :src="edit.thumbnail_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="thumbnail_image_url">
                      <img :src="thumbnail_image_url" width="128px" height="128px" ref="thumbnail_image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                  <b-form-group id="input-group-9" label="Pre Login Image [Upload Max File Size : 2MB]"
                    label-for="input-9" class="col-6">
                    <b-form-file id="input-9" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'pre_login_image')" ref="pre_login_image"></b-form-file>
                    <template v-if="
                      $route.name == 'edit-community' &&
                      edit.pre_login_image_url
                    ">
                      <img :src="edit.pre_login_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="pre_login_image_url">
                      <img :src="pre_login_image_url" width="128px" height="128px" ref="pre_login_image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group id="input-group-10" label="Mobile Screen Hand Banner [Upload Max File Size : 2MB]"
                    label-for="input-10" class="col-6">
                    <b-form-file id="input-10" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'pre_login_image2')" ref="pre_login_image2"></b-form-file>
                    <template v-if="
                      $route.name == 'edit-community' &&
                      edit.pre_login_image2_url
                    ">
                      <img :src="edit.pre_login_image2_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="pre_login_image2_url">
                      <img :src="pre_login_image2_url" width="128px" height="128px" ref="pre_login_image2_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                  <b-form-group id="input-group-13" label="Website Banner Image [Upload Max File Size : 2MB]"
                    label-for="input-13" class="col-6">
                    <b-form-file id="input-13" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'website_banner_image')" ref="website_banner_image"></b-form-file>
                    <template v-if="
                      $route.name == 'edit-community' &&
                      edit.website_banner_image_url
                    ">
                      <img :src="edit.website_banner_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="website_banner_image_url">
                      <img :src="website_banner_image_url" width="128px" height="128px" ref="website_banner_image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group id="input-group-12" class="col-6">
                    <label for="input-12">Banner Image [Upload Max File Size : 2MB] <span
                        style="color: red">*</span></label>
                    <b-form-file id="input-12" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'banner_image')" ref="banner_image" :class="{
                        'is-invalid': submitted && $v.banner_image.$invalid,
                      }"></b-form-file>
                    <template v-if="
                      $route.name == 'edit-community' && edit.banner_image_url
                    ">
                      <img :src="edit.banner_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="banner_image_url">
                      <img :src="banner_image_url" width="128px" height="128px" ref="banner_image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <div v-if="submitted && !$v.banner_image.required" class="invalid-feedback">
                      Banner Image is required.
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-11" class="col-6">
                    <label for="input-11">Logo Image [Upload Max File Size : 2MB] <span
                        style="color: red">*</span></label>
                    <b-form-file id="input-11" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'logo_image')" ref="logo_image" :class="{
                        'is-invalid': submitted && $v.logo_image.$invalid,
                      }"></b-form-file>
                    <template v-if="
                      $route.name == 'edit-community' && edit.logo_image_url
                    ">
                      <img :src="edit.logo_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="logo_image_url">
                      <img :src="logo_image_url" width="128px" height="128px" ref="logo_image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <div v-if="submitted && !$v.logo_image.required" class="invalid-feedback">
                      Logo Image is required.
                    </div>
                  </b-form-group>
                </div>
              </tab-content>
              <tab-content title="Add Tags" icon="fa fa-tag">
                <div class="row">
                  <b-form-group class="col-6 position-relative" id="input-group-5" label="Meta Title"
                    label-for="input-5">
                    <b-form-input id="input-5" :maxlength="190" v-model="form.meta_title"
                      placeholder="Enter Meta Title"></b-form-input>
                    <div class="text-right">
                      <p v-if="form.meta_title" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.meta_title.length !== 190,
                        'badge-danger': form.meta_title.length === 190
                      }">
                        You typed
                        {{ form.meta_title.length }} out of 190 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6 position-relative" label="Meta Keywords" label-for="input-7">
                    <b-form-input id="input-7" :maxlength="250" v-model="form.meta_keywords" placeholder="Enter Meta Keywords">
                    </b-form-input>
                    <div class="text-right">
                      <p v-if="form.meta_keywords" class="badge position-absolute" style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_keywords.length !== 250,
                          'badge-danger': form.meta_keywords.length === 250
                        }">
                        You typed
                        {{ form.meta_keywords.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                </div>
                <b-form-group id="input-group-6" label="Meta Description" label-for="input-6"
                  class="mb-4 position-relative">
                  <b-form-textarea id="input-6" v-model="form.meta_description" placeholder="Enter Meta Description"
                    rows="3" :maxlength="400"></b-form-textarea>
                  <div class="text-right">
                    <p v-if="form.meta_description" class="badge position-absolute" style="top: 4px; right: 13px"
                      :class="{
                        'badge-success': form.meta_description.length !== 400,
                        'badge-danger': form.meta_description.length === 400
                      }">
                      You typed
                      {{ form.meta_description.length }} out of 400 chars.
                    </p>
                  </div>
                </b-form-group>
                <template v-if="$route.name == 'edit-community'">
                  <h6>Tabs Listed:</h6>
                  <div v-for="(data, i) in forum_tabs" :key="i">
                    <label>Label for {{ i }} :</label>
                    <div class="row">
                      <div class="col-md-4">
                        <b-form-group label-for="case">
                          <b-form-input id="case" type="number" v-model="data.index" placeholder="Index"></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <b-form-group id="input-group-1" label-for="input-1" class="mb-0">
                          <b-form-input id="input-1" v-model="data.label" placeholder="Enter Label"></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-4" style="
                            display: flex;
                            align-items: start;
                            padding-top: 10px;
                          ">
                        <div style="display: flex; gap: 10px">
                          <b-form-checkbox v-model="data.active">Active</b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <b-form-group id="input-group-15">
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox v-model="form.available_for_registration">Available for Live Event Registration
                    </b-form-checkbox>
                    <b-form-checkbox v-model="form.show_in_home_page">Show in Home Page</b-form-checkbox>
                  </div>
                </b-form-group>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import communityMixin from "../../../mixins/ModuleJs/community";
import { required } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
  mixins: [MixinRequest, communityMixin],
  data() {
    return {
      submitted: false,
      title1: "Add Community",
      title2: "Edit Community",
      items: [
        {
          text: "Back",
          href: "/community",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      title: { required },
      news_card_color: { required },
    },
    logo_image: { required },
    banner_image: { required },
  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.title.$invalid || this.$v.form.news_card_color.$invalid || this.$v.banner_image.$invalid || this.$v.logo_image.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
  },
};
</script>
